import React, { useState, useCallback } from 'react';
import './FinancialAcumenELearningFlyer.component.css';
import { Grid, Typography, Box, Card, CardHeader, CardMedia, CardContent, CardActions, Avatar, Stack, CircularProgress, Link, IconButton, Dialog, Button } from '@mui/material';
import { red } from '@mui/material/colors';

import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import { useQueries } from '@tanstack/react-query';

import lambdaConfig from '../../config/lambda.config';

import axios from 'axios';

const FinancialAcumenELearningFlyer = () => {

  const [showCourseDetails, setShowCourseDetails] = useState(false);
  const [courseData, setCourseData] = useState<any>(null);
  const [courseLoading, setCourseLoading] = useState(false);


  const [courseListQuery] = useQueries({
    queries: [
      {
        queryKey: ['course-list'],
        queryFn: async () => {
          const courseListQueryResponse = await axios.post(lambdaConfig.processLMSRequestsEndpoint, {
            queryName: 'course-list'
          });
          return courseListQueryResponse;
        },
      }
    ],
  });
  const cardHeaderClick = useCallback(async (e: React.MouseEvent<HTMLElement>) => {
    setShowCourseDetails(true);
    setCourseLoading(true);
    // @ts-ignore
    // eslint-disable-next-line no-label-var, no-labels
    const courseQueryResponse = await axios.post(lambdaConfig.processLMSRequestsEndpoint, {
      queryName: 'course',
      courseId: e.currentTarget.id
    });
    console.log('courseQueryResponse.data', courseQueryResponse.data);
    setCourseData(courseQueryResponse.data);
    setCourseLoading(false);
  },[]);

  return (
    <Grid  container justifyContent='center' mt={{ xs:0, sm:5 }} mb={{ xs:0, sm:5 }}>
      <Grid className='mainContainer' item xs={12} sm={10} md={8} lg={8} >
        <Grid className='hero' >
          <Grid className='headerTextContainer' container justifyContent='center' sx={{ textAlign: 'center' }}>
            <Grid className='headerText' xs={10} >
              <Typography variant='h1'>Financial Acumen e-learning</Typography>
            </Grid>
          </Grid>
        </Grid>
        
        <Grid container mt={5}>
          <Grid item  className='paragraphText' xs={12} md={6} >
            <Typography>
              We are delighted to offer Financial Acumen - an e-learning program from Bonanza Education. Financial Training Australia (FTA) is the sole licensee and partner in
              Australia/New Zealand for Bonanza, who are recognized as one of the global leaders in finance training and are used by over 800,000 business leaders worldwide.<br /><br />

              This partnership enables us to supplement our Australian team with facilitators based in the US, Europe, China and SE Asia and use world-leading materials and state of the art
              blended learning platforms. This e-learning is one example.<br /><br />
              
              <span>The Visual Language of Finance®</span><br /><br />
              The Bonanza approach is unique in using the 'Visual Language of Finance' as well as gamification to make finance training extremely engaging and fun! It covers the fundamentals using a generic approach,
            </Typography>
          </Grid>
          <Grid item  className='paragraphText' xs={12} md={6}>
            <Typography>
              and can be used as a prerequisite to a facilitated program or as a stand alone program. Financial Acumen e-learning takes approximately 1.5 - 2 hours to complete.<br /><br />
              
              For some, the topic of finance can be viewed as daunting, so this program is helpful to give participants a basic introduction which they can complete at
              their own pace, thereby instilling confidence. When used as a pre-course, it also means that less time can be spent during the session on the basics and more
              time can be devoted to working with practical examples.
            </Typography>
            <br /><br />
            <Box className='imageWrapper'>
              <img className='center' src="../../assets/images/logos.png" width='300' alt=''/> 
            </Box>
          </Grid>
          <Grid item  className='line' xs={12} mt={5} mb={5}></Grid>
          <Grid item  className='paragraphText' xs={12} md={8}>
            <Typography>
              <span>Visualisation Makes the Complex Simple</span><br /><br />
              Cognitive research shows that visualization makes the uptake of new skills easier, faster and more consisting. Basically, it goes
              back to the way that our brains are wired, why a picture tells more than a thousand words (or numbers). The visual approach opens the subject of finance to all, not only to the number crunchers.
            </Typography>
          </Grid>
          <Grid item  className='graphImage' xs={12} md={4}></Grid>
          <Grid item  className='line' xs={12} mt={5} mb={5}></Grid>
          <Grid container>
            <Grid item  className='paragraphText' xs={12} >
              <img className='left' src="../../assets/images/bodyImage.png" width='300' alt=''/> 
              <span>Contents</span><br /><br />
                The course is made up of eight modules. You will start with an Introductory module, before the Personal Finance module will provide you with a strong foundation
                of the fundamentals of which finance is based on. The knowledge learned about Personal Finance will help you connect quickly and easily to the next two modules under
                the Business Finance Fundamentals banner; Balance Sheet and Profit and Loss Account. The Cash Flow and  Working Capital module builds on Business Finance further, before you are given the opportunity to
                apply your knowledge to Financial Structures and Situations. The next module contains a Game which incorporates your new learnings in a fun and engaging manner. The course will conclude with an Quiz
                that will allow you to utilise all your new financial skills. By completing the quiz, you will officially have completed the course!
              </Grid> 
            </Grid>
          </Grid>
          <Grid item  className='line' xs={12} mt={5} mb={5}></Grid>
          <Grid container>
            <Grid item  className='paragraphText' xs={12} >
              <img className='right' src="../../assets/images/gamification.png" width='300' alt=''/> 
              <span>Gamification</span><br /><br />
              Learning has to be engaging and fun. If your day of learning ends up in a headache, you have completely missed the mark. Gamification is a sure way to make
              people learn with a smile. We use a game as part of the e-learning experience to give you a chance to challenge yourself and apply your learnings about business finance,
              as well as potentially compete against your colleagues!
            </Grid> 
          </Grid>
          <Grid item className='line' xs={12} mt={5} mb={5}></Grid>
          <Grid container justifyContent='center' sx={{ textAlign: 'center'}}>
            <Grid item xs={12} sm={4} >
              <img src="../../assets/images/tick.png" width='150' alt=''/>  
              <Typography variant='h2'>
                Grow confidence in<br />
                financial<br />
                communication<br />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} >
              <img src="../../assets/images/tick.png" width='150' alt=''/> 
              <Typography variant='h2'>
                Engage your<br />
                organisation in the<br />
                numbers<br />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} >
              <img src="../../assets/images/tick.png" width='150' alt=''/>  
              <Typography variant='h2'>
                Fun, visual, effective<br />
                and measureable<br />
                learning<br />      
              </Typography>
            </Grid>
          </Grid>

        

        <Grid container mt={5}>
          <Grid item className='line' xs={12} mt={5} mb={5}></Grid>
          {
            courseListQuery?.isLoading
            ?
              <Grid item xs={12} justifyContent='center' alignItems='center'  sx={{ textAlign: 'center'}}>
                <CircularProgress />
              </Grid>
            :   
              courseListQuery.isError
              ? 
                <Grid item xs={12} justifyContent='center' alignItems='center'  sx={{ textAlign: 'center'}}>
                  Error loading courses
                </Grid>
              :
                <Grid item xs={12} justifyContent='center' alignItems='center' >
                  {
                    courseListQuery?.data?.data?.data?.map((course: any) => {
                      return (
                      <Link href="#" onClick={cardHeaderClick} id={course.id} key={`${course.id}-Link`}>
                        <Card sx={{ textAlign: 'left', maxWidth: '300px', display: 'inline-block', margin: '1rem', height: '500px', position: 'relative'}} key={`${course.id}-Card`}>
                          <Stack>
                            <CardHeader
                                avatar={
                                  <Avatar sx={{ bgcolor: red[500] }} aria-label="course">
                                    C
                                  </Avatar>
                                }
                                title={course.name}
                                subheader={`${course.creation_date.split(' ')[0].replace(',','')} ${course.price.replace('&#36;','$')}`}
                            />
                            <CardMedia
                                component="img"
                                height="194"
                                image={course.big_avatar}
                                alt={`${course.name} Image`}
                            />
                            <CardContent >
                                <Typography sx={{ height: '160px', overflow: 'hidden' }} variant="body2" color="text.secondary">{course.description}</Typography>
                            </CardContent>
                            <CardActions sx={{ position: 'absolute', bottom: '0' }} >
                                <IconButton aria-label="add to favorites">
                                  <FavoriteIcon />
                                </IconButton>
                                <IconButton aria-label="share">
                                  <ShareIcon />
                                </IconButton>
                                <IconButton aria-label="buy">
                                  <AddShoppingCartIcon />
                                </IconButton>
                            </CardActions>
                          </Stack>
                        </Card>
                      </Link>
                    )})
                  }
                </Grid>
          }
        </Grid> 
        <Grid container className='footer' justifyContent='center' alignItems='center' mt={5} >
            <Grid item xs={12} lg={3} >
                <a href='https://www.financialtrainingaustralia.com/contact/'>info@financialtrainingaustralia</a>
            </Grid>
            <Grid item xs={12} lg={3} >
                (03) 9510 9401
            </Grid>
            <Grid item xs={12} lg={3} >
                <a href='https://www.financialtrainingaustralia.com/'>www.financialtrainingaustralia.com</a>
            </Grid>
        </Grid>

      </Grid>
    </Grid> 
  )
}
export default FinancialAcumenELearningFlyer;