import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { CssBaseline, Box } from '@mui/material';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import FinancialAcumenELearningFlyer from './components/FinancialAcumenELearningFlyer.component/FinancialAcumenELearningFlyer.component';
import './App.css';

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: true,
        retry: false,
        staleTime: Infinity,
      },
    },
  });
  return (
    <Box className="App" sx={{ height: '100vh'}}>
      <CssBaseline>
        <QueryClientProvider client={queryClient} >
          <Router>
            <Routes>
              <Route path="/" element={ <FinancialAcumenELearningFlyer /> } />
              <Route path="/financial-acumen-flyer" element={ <FinancialAcumenELearningFlyer /> } />
            </Routes>
          </Router>
        </QueryClientProvider>
      </CssBaseline>
    </Box>
  );
}

export default App;
